import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer = {
	cinema: {
		address: 'ул. Первомайская, 3',
		place: null,
		phoneNumbers: ['+7 (86147) 2-55-74'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/id254652337',
		twitter: null,
		odnoklassniki: 'https://ok.ru/kinoteatr.pobeda',
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: 'https://yandex.ru/maps/-/CCrWYJ25',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
